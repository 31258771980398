import { gql } from '@apollo/client';

export const UpdateProjectDetails = gql`
  mutation UpdateProjectDetails($where: ProjectWhere, $update: ProjectUpdateInput) {
    updateProjects(where: $where, update: $update) {
      projects {
        name
        id
        startDate
      }
    }
  }
`;

export const QueryProjectsTablePagination = gql`
  query QueryProjectsTablePagination(
    $options: ProjectOptions
    $where: ProjectWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    projects(options: $options, where: $where) {
      name
      startDate
      active
      id
      peopleWithExperienceConnection(where: { edge: { active: true }, node: { active: true } }) {
        edges {
          role
          totalHours
          hoursPerWeek
          node {
            name
            email
            userIconUrl
            google_workLocation
            locationState
            slackUsername
            orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
              edges {
                position
                startDate
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
/*
{
  "options": null,
  "where": {
    "AND": [
      {
        "active": null,
        "name_NOT_STARTS_WITH": null
      }
    ]
  }
}
*/

export const QueryAllProjects = gql`
  query QueryAllProjects($where: ProjectWhere) {
    projects(where: $where) {
      name
      id
      startDate
    }
  }
`;

export const QueryProjectDetails = gql`
  query QueryProjectDetails($where: ProjectWhere, $peopleWithExperienceConnectionWhere: ExperiencePeopleWithExperienceConnectionWhere) {
    projects(where: $where) {
      name
      startDate
      active
      company
      description
      endDate
      hyperlinks
      id
      industry
      location
      sector
      startDate
      peopleWithExperienceConnection(where: $peopleWithExperienceConnectionWhere) {
        edges {
          role
          startDate
          endDate
          hoursPerWeek
          totalHours
          active
          node {
            cognitoID
            email
            active
            name
            userIconUrl
          }
        }
      }
    }
  }
`;

export const QueryUserProjects = gql`
  query QueryUserProjects(
    $where: ProjectWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    projects(where: $where) {
      name
      id
      company
      startDate
      organization {
        name
      }
      peopleWithExperienceConnection {
        edges {
          role
          hoursPerWeek
          node {
            email
            name
            userIconUrl
            google_workLocation
            locationState
            slackUsername
            orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
              edges {
                position
                startDate
                node {
                  name
                }
              }
            }
          }
        }
      }
      assessments {
        skillsConnection {
          edges {
            rating
            node {
              name
              slug
              imageLink
              description
              dateAdded
              category {
                type
                value
                icon
              }
            }
          }
        }
        dateCompleted
        description
        personCompleted {
          cognitoID
        }
      }
    }
  }
`;
/*
{
  "where": {
    "AND": [
      {
        "peopleWithExperience_SOME": {
          "email": "rshendler@jahnelgroup.com"
        },
        "name_NOT_STARTS_WITH": "JG"
      }
    ]
  }
}
*/

export const CreateProject = gql`
  mutation CreateProject($input: [ProjectCreateInput!]!) {
    createProjects(input: $input) {
      info {
        bookmark
      }
    }
  }
`;
/*
{
  "input": [
    {
      "name": null,
      "id": null,
      "company": null,
      "description": null,
      "startDate": null,
      "endDate": null,
      "active": null,
      "hyperlinks": null,
      "industry": null,
      "location": null,
      "sector": null,
      "organization": {
        "connect": [
          {
            "where": {
              "node": {
                "uid": null
              }
            }
          }
        ]
      }
    }
  ]
}
*/

export const UpdateProject = gql`
  mutation UpdateProject($where: ProjectWhere, $update: ProjectUpdateInput) {
    updateProjects(where: $where, update: $update) {
      info {
        bookmark
      }
    }
  }
`;
/*
{
  "where": {
    "id": null
  },
  "update": {
    "active": null
  }
}
*/

export const AddProjectMembers = gql`
  mutation AddProjectMembers($projectId: String!, $projectMembers: [ProjectMemberInput!]!) {
    addProjectMembers(projectId: $projectId, projectMembers: $projectMembers) {
      name
    }
  }
`;
/*
{
  "projectId": null,
  "projectMembers": []
}
*/

export const DeleteProject = gql`
  mutation DeleteProject($where: ProjectWhere, $delete: ProjectDeleteInput) {
    deleteProjects(where: $where, delete: $delete) {
      nodesDeleted
    }
  }
`;
/*
{
  "where": {
    "id": null
  },
  "delete": {
    "assessments": [
      {
        "where": {
          "node": {
            "experienceAssessedConnection_SOME": {
              "node": {
                "id": null
              }
            }
          }
        }
      }
    ]
  }
}
*/
