import { gql } from '@apollo/client';

export const QueryAssessment = gql`
  query QueryAssessment($where: AssessmentWhere) {
    assessments(where: $where) {
      assessmentId
      description
      link
      name
    }
  }
`;

export const QueryCompletedAssessments = gql`
  query QueryCompletedAssessment($where: AssessmentCompletionWhere) {
    assessmentCompletions(where: $where) {
      dateCompleted
      assessment {
        name
      }
      experienceAssessed {
        name
      }
      personCompleted {
        cognitoID
        name
        email
        userIconUrl
      }
    }
  }
`;

export const CreateUserSkillRatings = gql`
  mutation CreateUserSkillRatings($email: String!, $input: [CreateUserSkillRatingsInput!]!) {
    createUserSkillRatings(email: $email, input: $input) {
      email
    }
  }
`;

export const DeleteUserSkills = gql`
  mutation DeleteUserSkills($email: String!) {
    deleteUserSkills(email: $email) {
      email
    }
  }
`;

export const AddUserSkillAssessment = gql`
  mutation AddUserSkillAssessment($email: String!, $dateCompleted: String!) {
    addUserSkillAssessment(email: $email, dateCompleted: $dateCompleted) {
      dateCompleted
    }
  }
`;
/*
{
  "email": null,
  "dateCompleted": null
}
*/

export const AddProjectUserSkillRatings = gql`
  mutation AddProjectUserSkillRatings(
    $id: String!
    $input: [CreateProjectSkillRatingsInput!]!
    $userId: String!
  ) {
    addProjectUserSkillRatings(id: $id, input: $input, userId: $userId) {
      dateCompleted
    }
  }
`;

export const AddProjectSkillAssessment = gql`
  mutation AddProjectSkillAssessment($input: ProjectSkillAssessmentInput!) {
    addProjectSkillAssessment(input: $input) {
      id
    }
  }
`;

export const GetLastAssessment = gql`
  query GetLastAssessment(
    $where: PersonWhere
    $completedAssessmentsWhere2: AssessmentCompletionWhere
  ) {
    people(where: $where) {
      completedAssessments(where: $completedAssessmentsWhere2) {
        dateCompleted
      }
    }
  }
`;
/*
{
  "where": {
    "email": null
  },
  "completedAssessmentsWhere2": {
    "assessment_SOME": {
      "assessmentId": null
    }
  }
}
*/
