import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import moment from 'moment';
import { QueryUserExperience } from '../../../graphql/work_experience';
import Card from '../../shared/Card.component';
import { AvatarGroup, CheckboxInput } from '../../../custom-prebuilt/common.component';
import SearchLogic from '../../../custom-prebuilt/SearchLogic.component';
import { FilterIcon } from '@heroicons/react/outline';
import Modal from '../../../custom-prebuilt/Modal.component';
import { useSelector } from 'react-redux';

const transformSkill = (skill) => ({
  imageLink: skill.node?.imageLink,
  link: `/skills/${skill.node.slug}`,
  name: skill.node?.name,
});

const ProjectExperience = ({ userDetails }) => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);

  /**
   * Query projects that fall into 1 of 2 cases:
   * - JG projects that users have clocked more than 0 hour
   * - non JG projects (past experience from previous companies --- non Tense data)
   */
  const { data, error } = useQuery(QueryUserExperience, {
    variables: {
      where: {
        email: userDetails?.email,
      },
      experienceConnectionWhere2: {
        OR: [
          {
            node: {
              _on: {
                Project: {
                  organization_SOME: {
                    uid: defaultOrgUid
                  },
                  OR: [
                    {
                      name_NOT_STARTS_WITH: 'JG',
                    },
                    {
                      name_IN: ['JG - Connect Development', 'JG - Tense'],
                    },
                  ],

                },
              },
            },
            edge: {
              totalHours_GT: 0
            }
          },
          {
            node: {
              _on: {
                Project: {
                  organization_NONE: {
                    uid: defaultOrgUid
                  },
                },
              },
            }
          }
        ]
      },
      assessmentsWhere2: {
        personCompleted_SOME: {
          email: userDetails?.email,
        },
      },
    },
    skip: !userDetails?.email,
  });

  const OrganizationName = userDetails?.orgUnitsConnection.edges[0]?.node?.name;

  const [experience, setExperience] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(true);
  const [pastFilter, setPastFilter] = useState(true);
  const [curFiltInput, setCurFiltInput] = useState(true);
  const [pastFiltInput, setPastFiltInput] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleSaveModal = () => {
    setShowModal(false);
    setCurrentFilter(curFiltInput);
    setPastFilter(pastFiltInput);
  };

  useEffect(() => {
    if (!data) return;

    let personalProjects = [];
    let orgProjects = [];

    // Filter
    if (pastFilter) {
      personalProjects = data?.people?.[0]?.experienceConnection.edges
        ?.filter((pc) => pc.node.organization.length === 0)
        .map((pc) => {
          const skills = groupBy(pc.node?.assessments?.[0]?.skillsConnection?.edges, 'rating');

          return {
            name: pc.node?.name,
            company: pc.node?.company,
            hours: pc.totalHours,
            location: pc.node?.location,
            startDate: pc.startDate,
            endDate: pc.endDate,
            primarySkills: skills?.[1]?.map(transformSkill) || [],
            secondarySkills: skills?.[2]?.map(transformSkill) || [],
            passiveSkills: skills?.[3]?.map(transformSkill) || [],
            description: pc.node?.assessments?.[0]?.description,
          };
        });
    }
    if (currentFilter) {
      orgProjects = data?.people?.[0]?.experienceConnection.edges
        ?.filter((pc) => pc.node.organization.length > 0)
        .map((pc) => {
          const skills = groupBy(pc.node?.assessments?.[0]?.skillsConnection?.edges, 'rating');

          return {
            name: pc.node?.name,
            company: pc.node?.company,
            hours: pc.totalHours,
            location: pc.node?.location,
            startDate: pc.startDate,
            endDate: pc.endDate,
            primarySkills: skills?.[1]?.map(transformSkill) || [],
            secondarySkills: skills?.[2]?.map(transformSkill) || [],
            passiveSkills: skills?.[3]?.map(transformSkill) || [],
            description: pc.node?.assessments?.[0]?.description,
          };
        });
    }

    const compareDates = (one, two) => {
      if (!one && !two) return 0;
      if (!one && two) return -1;
      if (one && !two) return 1;
      const date1 = moment(one);
      const date2 = moment(two);
      if (date1.isSame(date2)) return 0;
      return date1.isAfter(date2) ? -1 : 1;
    };

    // Sort
    const sortedExperience = [].concat(personalProjects, orgProjects)?.sort((a, b) => {
      const endDateComparison = compareDates(a.endDate, b.endDate);
      return endDateComparison ? endDateComparison : compareDates(a.startDate, b.startDate);
    });

    // Search
    const pattern = new RegExp(searchInput, 'gi');
    const filteredExperience = sortedExperience.filter((e) => {
      const valueList = [
        e.name,
        e.company,
        e.location,
        e.description,
        ...e.primarySkills.map((s) => s.name),
        ...e.secondarySkills.map((s) => s.name),
        ...e.passiveSkills.map((s) => s.name),
      ].filter((s) => s);
      return valueList.some((s) => pattern.test(s));
    });
    setExperience(filteredExperience);
    setLoading(false);
  }, [setExperience, data, error, searchInput, pastFilter, currentFilter]);

  return (
    <>
      <div className="mt-1 mb-4 flex items-center space-x-4">
        <input
          type="search"
          name="search"
          id="search"
          className="block w-full sm:max-w-sm rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature
					py-2 m-1 text-xs"
          placeholder="Search projects and work experience"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <button
          onClick={() => setShowModal(true)}
          className="inline-flex justify-center rounded-md border border-gray-300 bg-gray-800 px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-700
			focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2 transition duration-300"
        >
          <FilterIcon className="h-4 w-4 text-white" aria-hidden="true" />
        </button>
      </div>

      <SearchLogic
        loading={loading}
        dataCheck={experience.length}
        dataComponent={
          <div className="space-y-4">
            {experience.map((e) => (
              <Card key={e.name}>
                <div className="mt-4">
                  <div className="text-xs font-semibold">{e.name}</div>
                  <div className="text-xs text-font-dark mb-2">
                    {[e.company, e.location].filter((a) => a).join(', ')}
                  </div>
                  <div className="text-xs">
                    {e.startDate || '?'} — {e.endDate || 'present'}{' '}
                    {e.hours && `(${e.hours} hours)`}
                  </div>
                </div>
                <ul>
                  <li>
                    <AvatarGroup
                      label="• Primary Skills"
                      labelTip="The skill was used for a majority of your time spent on the project."
                      avatars={e.primarySkills}
                      limit="10"
                    />
                  </li>
                  <li>
                    <AvatarGroup
                      label="• Secondary Skills"
                      labelTip="The skill was used for part of your time spent on the project."
                      avatars={e.secondarySkills}
                      limit="10"
                    />
                  </li>
                  <li>
                    <AvatarGroup
                      label="• Passive Skills"
                      labelTip="The skill was seldomly used."
                      avatars={e.passiveSkills}
                      limit="10"
                    />
                  </li>
                </ul>
                <div className="mt-2 text-xs">{e.description}</div>
              </Card>
            ))}
          </div>
        }
        searchInput={searchInput}
      // reloadCondition={applyFilter}
      />
      <Modal
        open={showModal}
        title="Select either one or both to apply filter(s)"
        content={
          <div className="flex flex-col space-y-2 mb-2">
            <CheckboxInput
              checked={curFiltInput}
              onChange={() => setCurFiltInput((c) => !c)}
              name="active"
            >
              Projects for {OrganizationName}
            </CheckboxInput>
            <CheckboxInput
              checked={pastFiltInput}
              onChange={() => setPastFiltInput((p) => !p)}
              name="inactive"
            >
              Prior Work Experience
            </CheckboxInput>
          </div>
        }
        primaryButtonLabel="Apply"
        onPrimaryButtonClick={handleSaveModal}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => {
          setShowModal(false);
          setCurFiltInput(currentFilter);
          setPastFiltInput(pastFilter);
        }}
      />
    </>
  );
};

export default ProjectExperience;
