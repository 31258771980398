import LoadingState from './preloader/LoadingState.component';
import NoResults from './NoResults.component';
import { useEffect, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';

export type SearchLogic = {
  loading: boolean;
  searchInput: string;
  dataComponent: JSX.Element;
  dataCheck: boolean;
  reloadCondition?: boolean;
  showRequestInput?: boolean;
  requestType?: string;
};
/**
 * A component for Search Logic. Expected behavior: On first load, show spinner. After data is retrieved, it'll be filtered
 * based on users' search input. When a searchInput is invalid, show no results message
 * @param {*} loading Boolean. When first evaluated to false, loading spinner will never be shown again unless data is changed
 * @param {*} searchInput Searchinput to display in No Results component
 * @param {*} dataComponent Component that displays the normal data after data is retrieved
 * @param {*} dataCheck check if there is results or not. If yes, filter data as usual. Otherwise, display no results message
 * @param {*?} reloadCondition When this is changed, the searchLogic will be reset and the spinning will be shown
 * @param {*?} showRequestInput If true, add an input to the No Results page that allows users to request an item
 * @param {*?} requestType What type of item the user is requesting
 * @returns
 */
const SearchLogic = ({
  loading,
  searchInput,
  dataComponent,
  dataCheck,
  reloadCondition,
  showRequestInput = false,
  requestType = '',
}: SearchLogic) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [parent] = useAutoAnimate<HTMLDivElement>({ duration: 150 });

  useEffect(() => {
    if (!loading) {
      setFirstLoad(false);
    }
  }, [loading]);

  useEffect(() => {
    reloadCondition === true && setFirstLoad(true);
  }, [reloadCondition]);

  return (
    <div ref={parent} className="overflow-y-auto overflow-x-hidden">
      {firstLoad ? (
        <LoadingState />
      ) : dataCheck ? (
        dataComponent
      ) : (
        <NoResults
          searchInput={searchInput}
          showRequestInput={showRequestInput}
          requestType={requestType}
        />
      )}
    </div>
  );
};

export default SearchLogic;
