import { useMutation, useQuery } from '@apollo/client';
import autoAnimate from '@formkit/auto-animate';
import { useCallback, useEffect, useRef, useState } from 'react';
import Accordion from '../Accordion.component';
import LoadingState from '../preloader/LoadingState.component';
import {
  AddProjectMembers,
  QueryProjectDetails,
  UpdateProjectDetails,
  UpdateProject,
  DeleteProject,
} from '../../graphql/projects';
import { formatDate } from '../../lib/formatDate';
import toast from 'react-hot-toast';
import Modal from '../Modal.component';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSlideOver } from '../../redux/slideOverSlice';
import { DetailRow } from './utils/UserDetails.component';
import EditInfo from '../../components/admin/edit/EditInfo.component';
import { industries } from '../../components/settings/AddWorkExperience.component';
import AddProjectMember from '../../components/admin/projects/AddProjectMember';
import { formatArray } from '../../components/admin/edit/EditInfoContent.component';
import ProjectMemberDetails from './utils/ProjectMemberDetails';

const noop = () => {};

type DynamicDataType = {
  [key: string]: any;
};

export type SnapshotProjectProps = {
  target: DynamicDataType;
  editable: boolean;
};

const SnapshotProject = ({ target, editable }: SnapshotProjectProps) => {
  // target.name === 'JG - Tense'
  // ? {
  //     where: { id: target?.id },
  //   }
  // : {
  //     where: { id: target?.id },
  //     peopleWithExperienceConnectionWhere: {
  //       edge: {
  //         totalHours_GT: 0,
  //       },
  //     },
  //   }
  const { data, loading } = useQuery(QueryProjectDetails, {
    variables: {
      where: { id: target?.id },
    },
    skip: !target?.id,
  });
  const [projectDetails, setProjectDetails] = useState<DynamicDataType>({});
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateProject] = useMutation(UpdateProject, { refetchQueries: ['QueryProjectDetails'] });
  const [deleteProject] = useMutation(DeleteProject, { refetchQueries: ['QueryAllProjects'] });
  const isCurrentAdmin = useSelector((state: RootState) => state.org.isCurrentAdmin) && editable;

  // add user
  const [addModal, setAddModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    action: noop,
    type: '',
  });
  const [newMember, setNewMember] = useState<any>();
  const [showInputErrors, setShowInputErrors] = useState(false);
  const [addProjectMembers] = useMutation(AddProjectMembers);

  const dispatch = useDispatch();
  const parentRef = useRef<HTMLDivElement>();
  const [updateProjectDetails] = useMutation(UpdateProjectDetails);

  useEffect(() => {
    if (!data) return;
    setProjectDetails(data?.projects?.[0]);
  }, [data]);
  const isActive = !!projectDetails?.active;
  const handleOpenEditInfo = useCallback(
    (target: string, field: string, oldValue: any, id: any, type: any) => {
      const editObject = { target, field, oldValue, id, type };
      dispatch(toggleSlideOver({ show1: true, editObject }));
    },
    [dispatch],
  );

  useEffect(() => {
    parentRef.current && autoAnimate(parentRef.current);
  }, [parentRef]);

  const changeProjectStatus = async () => {
    await updateProject({
      variables: {
        where: {
          id: target.id,
        },
        update: {
          active: !isActive,
        },
      },
    });
    setShowDeactivateModal(false);
    window.location.reload();
  };

  const handleDeleteProject = async () => {
    await deleteProject({
      variables: {
        where: {
          id: target.id,
        },
        delete: {
          assessments: [
            {
              where: {
                node: {
                  experienceAssessedConnection_SOME: {
                    node: {
                      id: target.id,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    });
    setShowDeleteModal(false);
    window.location.reload();
  };
  const projectFields = [
    { field: 'description', label: 'Description', type: 'string' },
    { field: 'company', label: 'Company', type: 'string' },
    { field: 'location', label: 'Location', type: 'string' },
    { field: 'active', label: 'Active', type: 'boolean' },
    { field: 'startDate', label: 'Start Date', type: 'date' },
    { field: 'endDate', label: 'End Date', type: 'date' },
    {
      field: 'industry',
      label: 'Industry',
      type: industries.map((e) => ({
        label: e,
        value: e,
      })),
    },
    {
      field: 'sector',
      label: 'Sector',
      type: [
        { value: 'private', label: 'Private' },
        { value: 'public', label: 'Public' },
      ],
    },
    { field: 'hyperlinks', label: 'Hyperlinks', type: 'array' },
  ];

  const updateProjectUser = (editObject: any) => {
    const deleteUser = {
      disconnect: { where: { node: { cognitoID: editObject.userId } } },
    };
    const updateUser = {
      where: { node: { cognitoID: editObject.userId } },
      update: { edge: { active: editObject.value } },
    };
    const updateVariables = editObject.value == null ? deleteUser : updateUser;
    toast.promise(
      updateProjectDetails({
        refetchQueries: ['QueryAllProjects', 'QueryProjectDetails'],
        variables: {
          where: { id: editObject.projectId },
          update: { peopleWithExperience: [updateVariables] },
        },
      }).then(() => {
        dispatch(toggleSlideOver({ show1: false }));
        setTimeout(() => {
          dispatch(toggleSlideOver({ editObject: null }));
        }, 300);
      }),
      {
        loading: 'Updating...',
        success: 'Updated',
        error: 'Could not update',
      },
      { position: 'top-center' },
    );
  };

  return (
    <>
      <div className="flex h-full rounded-md flex-col overflow-y-scroll bg-bg_lightgray">
        {/* Main */}
        <div className="divide-y divide-gray-200">
          <div className="pb-4 bg-bg_light px-4">
            {/* <img className='h-32 w-full object-cover lg:h-48' src="https://media-exp1.licdn.com/dms/image/C4D1BAQErgnzr7eFwMg/company-background_10000/0/1604001615976?e=1664002800&v=beta&t=MMA3JnmDotb4wdxxx2g0Ix7XWwl1gCBtuaKOaVk2Ui8" alt="" /> */}
            <div className="flow-root px-4 sm:flex sm:items-end sm:px-6 bg-bg_light pb-8 rounded-lg pt-6">
              <div className="sm:ml-6 sm:flex-1">
                <div>
                  <div className="flex items-center">
                    <h3 className="text-base font-bold text-gray-900">{target && target.name}</h3>
                  </div>
                  {/* <p className="text-sm text-gray-500 mb-2">{target && target.email}</p> */}
                  <p className="text-sm font-medium text-gray-900">
                    {formatDate(target?.startDate, 'N/A')}
                  </p>
                </div>
                <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                  <button
                    onClick={() => {
                      // navigate(`/${target.email.split('.')[0]}`);
                      dispatch(toggleSlideOver({ show: false }));
                    }}
                    type="button"
                    className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md
                              border border-transparent bg-gray-800 px-4 py-2 text-xs font-medium text-white
                              shadow-sm hover:bg-gray-700 sm:flex-1 transition duration-150"
                  >
                    Full profile
                  </button>
                  <a href={projectDetails?.websiteLink} target="_blank" rel="noreferrer">
                    <button
                      className="inline-flex w-full flex-1 items-center justify-center rounded-md border
                                border-gray-300 bg-bg_light px-4 py-2 text-xs font-medium text-gray-700 shadow-sm
                                hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Website
                    </button>
                  </a>
                  {isCurrentAdmin && (
                    <button
                      onClick={() => setShowDeactivateModal(true)}
                      className="rounded-md border bg-bg_light border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    >
                      {isActive ? 'Deactivate' : 'Reactivate'}
                    </button>
                  )}
                  {isCurrentAdmin && !isActive && (
                    <button
                      onClick={() => setShowDeleteModal(true)}
                      className="rounded-md border bg-bg_light border-gray-300 px-4 py-2 text-xs font-bold text-red-700 hover:text-red-500 shadow-sm hover:bg-gray-50"
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <LoadingState />
          ) : (
            <>
              <Accordion
                expandAll={true}
                title={<span>Details</span>}
                content={
                  <>
                    {projectFields.map(({ field, label, type }, index) => (
                      <DetailRow
                        key={index}
                        label={label}
                        field={
                          !projectDetails?.[field]
                            ? 'N/A' // eslint-disable-next-line
                            : type === 'date'
                            ? formatDate(projectDetails[field], 'N/A')
                            : type === 'array'
                            ? formatArray(projectDetails[field])
                            : projectDetails[field].toString()
                        }
                        editable={isCurrentAdmin && editable}
                        onEdit={() => {
                          handleOpenEditInfo(
                            'project',
                            field,
                            projectDetails[field],
                            projectDetails?.id,
                            type,
                          );
                        }}
                      />
                    ))}
                  </>
                }
              />
              <ProjectMemberDetails
                title="Active Project Members"
                memberList={
                  projectDetails?.peopleWithExperienceConnection?.edges?.filter(
                    (p: any) => p.active && p.node.active,
                  ) ?? []
                }
                setAddModal={setAddModal}
                editable={editable && isCurrentAdmin}
                showButton={true}
                onEdit={(obj: any, field: string, type: string) => {
                  handleOpenEditInfo(
                    'project-user',
                    field,
                    obj[field],
                    {
                      projectId: projectDetails?.id,
                      userId: obj.node.cognitoID,
                    },
                    type,
                  );
                }}
                setConfirmModal={(obj: any, type: string, value: any) => {
                  setConfirmModal({
                    open: true,
                    type,
                    action: () => {
                      setConfirmModal({
                        open: false,
                        action: noop,
                        type: '',
                      });
                      updateProjectUser({
                        projectId: projectDetails?.id,
                        userId: obj.node.cognitoID,
                        value,
                      });
                    },
                  });
                }}
              />
              <ProjectMemberDetails
                title="Inactive Project Members"
                memberList={
                  projectDetails?.peopleWithExperienceConnection?.edges?.filter(
                    (p: any) => !p.active || !p.node.active,
                  ) ?? []
                }
                setAddModal={setAddModal}
                editable={editable && isCurrentAdmin}
                showButton={false}
                onEdit={(obj: any, field: string, type: string) => {
                  handleOpenEditInfo(
                    'project-user',
                    field,
                    obj[field],
                    {
                      projectId: projectDetails?.id,
                      userId: obj.node.cognitoID,
                    },
                    type,
                  );
                }}
                setConfirmModal={(obj: any, type: string, value: any) => {
                  setConfirmModal({
                    open: true,
                    type,
                    action: () => {
                      setConfirmModal({
                        open: false,
                        action: noop,
                        type: '',
                      });
                      updateProjectUser({
                        projectId: projectDetails?.id,
                        userId: obj.node.cognitoID,
                        value,
                      });
                    },
                  });
                }}
              />
            </>
          )}
        </div>
        {isCurrentAdmin && editable && <EditInfo />}
      </div>
      <Modal
        title={
          confirmModal.type === 'reactivate' ? (
            <span>Are you sure you want to reactivate this project member?</span>
          ) : confirmModal.type === 'deactivate' ? (
            <span>Are you sure you want to deactivate this project member?</span>
          ) : confirmModal.type === 'delete' ? (
            <span>Are you sure you want to remove this project member?</span>
          ) : (
            ''
          )
        }
        open={confirmModal.open}
        primaryButtonLabel={confirmModal.type === 'reactivate' ? 'Reactivate' : undefined}
        onPrimaryButtonClick={confirmModal.action}
        destructiveButtonLabel={
          confirmModal.type === 'deactivate'
            ? 'Deactivate'
            : confirmModal.type === 'delete'
            ? 'Remove'
            : undefined
        }
        onDestructiveButtonClick={confirmModal.action}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() =>
          setConfirmModal({
            open: false,
            action: noop,
            type: '',
          })
        }
        content={<></>}
      />
      <Modal
        title="Add project member"
        content={
          <div className="flex flex-col space-y-4">
            <AddProjectMember
              showInputErrors={showInputErrors}
              members={projectDetails?.peopleWithExperienceConnection?.edges?.map((p: any) => ({
                member: p.node,
              }))}
              onChange={setNewMember}
            />
          </div>
        }
        open={addModal}
        primaryButtonLabel="Add Project Member"
        onPrimaryButtonClick={() => {
          setShowInputErrors(true);
          if (!newMember) return;

          const membersInput = [newMember].map((m) => ({
            userId: m.member.cognitoID,
            active: m.active,
            role: m.role,
            startDate: m.startDate,
            endDate: m.endDate,
            totalHours: parseFloat(m.totalHours),
            hoursPerWeek: parseFloat(m.hoursPerWeek),
          }));
          const createPromise = addProjectMembers({
            variables: {
              projectId: projectDetails.id,
              projectMembers: membersInput,
            },
            refetchQueries: ['QueryAllProjects', 'QueryProjectDetails'],
          });
          toast.promise(createPromise, {
            loading: 'Adding member...',
            success: 'Successfully added member!',
            error: 'An error occurred!',
          });
          setAddModal(false);
          setShowInputErrors(false);
        }}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => {
          setAddModal(false);
          setShowInputErrors(false);
        }}
      />
      <Modal
        open={showDeactivateModal}
        onSecondaryButtonClick={() => setShowDeactivateModal(false)}
        secondaryButtonLabel="Cancel"
        primaryButtonLabel={isActive ? undefined : 'Reactivate'}
        destructiveButtonLabel={isActive ? 'Deactivate' : undefined}
        onDestructiveButtonClick={changeProjectStatus}
        onPrimaryButtonClick={changeProjectStatus}
        title={
          isActive
            ? 'Are you sure you want to deactivate this project?'
            : 'Are you sure you want to reactivate this project?'
        }
        content={
          <div>
            <div className="h-fit">
              <div className="text-sm text-gray-500">
                <div className="h-full w-full flex flex-col items-center">
                  <div className="w-full">
                    {isActive
                      ? 'Deactivating will hide the project but it can be re-activated to restore visibility'
                      : 'Reactivating will restore project visibility'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Modal
        open={showDeleteModal}
        onSecondaryButtonClick={() => setShowDeleteModal(false)}
        secondaryButtonLabel="Cancel"
        destructiveButtonLabel="Delete"
        onDestructiveButtonClick={handleDeleteProject}
        title={
          <span>
            Are you sure you want to <em className="text-red-800 font-bold">permanently</em> delete
            this project?
          </span>
        }
        content={
          <div>
            <div className="h-fit">
              <div className="text-sm text-gray-500">
                <div className="h-full w-full flex flex-col items-center">
                  <div className="w-full">
                    Doing so will remove all data related to this project from Connect.
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default SnapshotProject;
